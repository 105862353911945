import React from "react"

import Seo from "../components/seo"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import Button from "../components/button"
import { twitterMessage, backButtonContainer } from "./contact.module.scss"


const ContactSuccessPage = ({ data }) => (
    <>
        <Seo title="Mail received!"/>
        <h1>
            Mail sent!
        </h1>
        <p>
            Thank you very much for your message, we'll be in touch soon!
        </p>
        <p className={twitterMessage}>
            <em>You can also always reach me by sending a direct message on Twitter via <a
                href="https://twitter.com/thomas_wint" target="_blank" rel="noopener noreferrer">@thomas_wint</a>.</em>
        </p>
        <div className={backButtonContainer}>
            <Button link={"/"} icon={faArrowLeft} text={"Back to the website"} local={true}/>
        </div>
    </>
)

export default ContactSuccessPage
